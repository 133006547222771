import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import ButtonComponent from "../components/ButtonComponent";
import DropdownComponent from "../components/DropdownComponent/DropdownComponent";
import InputComponent from "../components/InputComponent";
import LayoutComponent from "../components/LayoutComponent";
import PopupComponent from "../components/PopupComponent";
import { useAxios } from "../utils/AxiosUtil";
import {
  Checkup,
  CovidState,
  createNewCheckup,
} from "../utils/checkup/Checkup.types";
import { saveCheckup } from "../utils/checkup/Checkup.util";
import { Employee } from "../utils/employee/Employee.types";
import { UserContext } from "./App";

const CheckupPage: React.FC<{}> = () => {
  const [covidState, setCovidState] = useState<CovidState>(CovidState.NONE);
  const location = useLocation<{ employee: Employee }>();
  const { t } = useTranslation();
  const history = useHistory();
  const axios = useAxios();
  const user = useContext(UserContext);
  const [enteredDate, setEnteredDate] = useState<string>("");
  const [showWarning, toggleWarning] = useState<boolean>(false);
  const [checkup, setCheckup] = useState<Checkup>();
  const [showPopup, togglePopup] = useState<boolean>(false);

  // create the new checkup instance as soon as possible
  useEffect(() => {
    if (location.state.employee && !checkup) {
      setCheckup(createNewCheckup(location.state.employee, user?.id!));
    }
    // eslint-disable-next-line
  }, [location]);

  // updates the date on state change
  useEffect(() => {
    if (
      covidState === CovidState.TESTED ||
      covidState === CovidState.TESTED_EXTERN
    ) {
      setEnteredDate(new Date().toISOString().slice(0, -8));
    } else {
      setEnteredDate("");
    }
  }, [covidState]);

  /**
   * Helper to generate the correct date/time input with the correct label
   *
   * @returns the correct date input
   */
  const getCorrectDateField = (): React.ReactElement => {
    let type: "datetime-local" | "date" = "date";
    let label: string = "";
    switch (covidState) {
      case CovidState.VACCINATED:
      case CovidState.BOOSTERED:
        label = t("checkup.date.vaccinated");
        break;
      case CovidState.TESTED:
      case CovidState.TESTED_EXTERN:
      case CovidState.TESTED_PCR:
      case CovidState.TESTED_PCR_EXTERN:
        type = "datetime-local";
        label = t("checkup.date.tested");
        break;
      case CovidState.CURED:
        label = t("checkup.date.cured");
        break;
      case CovidState.NONE:
        break;
    }
    if (covidState !== CovidState.NONE)
      return (
        <>
          <p>{label}</p>
          <InputComponent
            onChange={setEnteredDate}
            value={enteredDate}
            type={type}
          />
        </>
      );
    else return <></>;
  };

  /**
   * Helper to finalize the Checkup and show the confirm popup
   */
  const generateNewCheckup = (): void => {
    toggleWarning(false);
    let workingCopy: Checkup = checkup!;
    workingCopy.state = covidState;
    if (!enteredDate) {
      toggleWarning(true);
      return;
    }
    workingCopy.vaccinatedDay = undefined;
    workingCopy.testDate = undefined;
    workingCopy.curedEndDay = undefined;
    switch (covidState) {
      case CovidState.VACCINATED:
        workingCopy.vaccinatedDay = new Date(enteredDate);
        break;
      case CovidState.BOOSTERED:
        workingCopy.boosterDay = new Date(enteredDate);
        break;
      case CovidState.TESTED:
      case CovidState.TESTED_EXTERN:
      case CovidState.TESTED_PCR:
      case CovidState.TESTED_PCR_EXTERN:
        workingCopy.testDate = new Date(enteredDate);
        break;
      case CovidState.CURED:
        workingCopy.curedEndDay = new Date(enteredDate);
        break;
      case CovidState.NONE:
        toggleWarning(true);
        return;
    }
    setCheckup(workingCopy);
    togglePopup(true);
  };

  /**
   * Helper to close the popup and either persist the checkup and
   * route back to the dashboard or just close it
   *
   * @param saveIt boolean to define if the checkup should be persisted
   */
  const popupCloseCall = (saveIt: boolean): void => {
    if (saveIt) {
      saveCheckup(checkup!, axios).then((success) => {
        if (success) history.push("/dashboard");
        else toggleWarning(true);
      });
    } else {
      togglePopup(false);
    }
  };

  return (
    <LayoutComponent>
      <h2>
        {t("checkup.title", {
          replace: { name: location?.state?.employee?.name },
        })}
      </h2>
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          generateNewCheckup();
        }}
      >
        <DropdownComponent
          onChange={(newValue) => setCovidState(newValue as CovidState)}
          options={Object.values(CovidState).map((state) => ({
            label: t(`checkup.covidState.${state}`),
            value: state,
            disabled: state === CovidState.NONE,
          }))}
          selectedValue={covidState as string}
          classNameOptions={"dropdown-style"}
          classNameOption={"dropdown-wrapper"}
          classNameClearIcon={"dropdown-clear"}
        />
        {getCorrectDateField()}
        <ButtonComponent
          onClick={() => generateNewCheckup()}
          title={t("general.enter")}
        />
        {showWarning && (
          <p className="not-found-warning">{t("general.failure")}</p>
        )}
        <ButtonComponent
          onClick={() => history.push("/dashboard")}
          title={t("general.cancel")}
          background="#df081d"
        />
      </form>
      <PopupComponent
        visible={showPopup}
        checkup={checkup!}
        name={location?.state?.employee?.name}
        closeFunction={popupCloseCall}
      />
    </LayoutComponent>
  );
};

export default CheckupPage;
