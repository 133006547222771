const LoaderComponent: React.FC<{ small?: boolean }> = ({ small }) => {
  return (
    <div className={["loading-wrapper", small ? "small" : ""].join(" ")}>
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

export default LoaderComponent;
