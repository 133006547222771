import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ButtonComponent from "../components/ButtonComponent";
import DisplayListComponent from "../components/DisplayListComponent";
import LayoutComponent from "../components/LayoutComponent";
import { useAxios } from "../utils/AxiosUtil";
import { User } from "../utils/user/User.types";
import { loadAllTestUser } from "../utils/user/User.utils";

const TesterPage: React.FC<{}> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const axios = useAxios();
  const [tester, setTester] = useState<User[]>([]);

  useEffect(() => {
    if (!axios) return;
    loadAllTestUser(axios).then((response) => setTester(response));
  }, [axios]);

  return (
    <LayoutComponent>
      <h2>{t("tester.title")}</h2>
      {tester.length > 0 ? (
        <DisplayListComponent entries={tester} />
      ) : (
        <small>{t("tester.noEntries")}</small>
      )}
      <ButtonComponent
        title={t("general.back")}
        onClick={() => history.push("/dashboard")}
      />
    </LayoutComponent>
  );
};

export default TesterPage;
