import { User } from "../utils/user/User.types";

interface DisplayListComponentProps {
  entries: User[];
}

const DisplayListComponent: React.FC<DisplayListComponentProps> = ({
  entries,
}) => {
  return (
    <div className={`select-list-component`}>
      {entries.map((entry, index) => (
        <div
          key={`select-entry-${index}`}
          className="select-list-component--entry no-click"
        >
          {entry.lastName}, {entry.firstName} <br />({entry.employeeId})
        </div>
      ))}
    </div>
  );
};

export default DisplayListComponent;
