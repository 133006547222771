import { useEffect, useRef, useState } from "react";
import { useAxios } from "../utils/AxiosUtil";
import {
  uploadCheckupCsv,
  uploadTesterCsv,
} from "../utils/upload/Upload.utils";
import LoaderComponent from "./LoaderComponent";

interface ButtonComponentProps {
  title: string;
  onClick?(): void;
  disabled?: boolean;
  background?: string;
  fileUpload?: boolean;
  fileType?: "tester" | "checkup";
  uploadSuccess?(success: boolean): void;
  uploadFail?(success: boolean): void;
}

const ButtonComponent: React.FC<ButtonComponentProps> = ({
  onClick,
  title,
  disabled,
  background,
  fileUpload,
  uploadSuccess,
  uploadFail,
  fileType,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [loading, toggleLoading] = useState<boolean>(false);
  const axios = useAxios();
  const [selectedFile, setSelectedFile] = useState<File>();

  // listens on file changes and uploads the file as soon as there is one
  useEffect(() => {
    if (!selectedFile) return;
    toggleLoading(true);
    if (fileType === "tester")
      uploadTesterCsv(selectedFile, axios).then((success) => {
        setSelectedFile(undefined);
        uploadSuccess && uploadSuccess(success);
        uploadFail && uploadFail(!success);
        toggleLoading(false);
      });
    else if (fileType === "checkup")
      uploadCheckupCsv(selectedFile, axios).then((success) => {
        setSelectedFile(undefined);
        uploadSuccess && uploadSuccess(success);
        uploadFail && uploadFail(!success);
        toggleLoading(false);
      });
    //eslint-disable-next-line
  }, [selectedFile]);

  return (
    <div
      onClick={() =>
        onClick && !loading
          ? onClick()
          : fileUpload && !loading
          ? inputRef.current!.click()
          : {}
      }
      className={`button-component ${disabled ? "disabled" : null}`}
      style={background ? { backgroundColor: background } : {}}
    >
      <input
        hidden
        ref={inputRef}
        type="file"
        accept=".csv"
        onChange={(evt) => setSelectedFile(evt.target.files![0])}
      />
      {loading ? <LoaderComponent small /> : title}
    </div>
  );
};

export default ButtonComponent;
