export interface User {
  id?: string;
  username: string;
  firstName: string;
  lastName: string;
  mail: string;
  role: UserRole;
  password: string;
  employeeId: string;
}

export enum UserRole {
  ADMIN = "ADMIN",
  TESTER = "TESTER",
  EMPLOYEE = "EMPLOYEE",
}
