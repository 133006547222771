import { Employee } from "../employee/Employee.types";

/**
 * The different states a checkup can have
 */
export enum CovidState {
  NONE = "NONE",
  VACCINATED = "VACCINATED",
  BOOSTERED = "BOOSTERED",
  TESTED = "TESTED",
  TESTED_EXTERN = "TESTED_EXTERN",
  TESTED_PCR = "TESTED_PCR",
  TESTED_PCR_EXTERN = "TESTED_PCR_EXTERN",
  CURED = "CURED",
}

/**
 * The actual checkup. This is only a subset of fields
 * that are available on the backend but the client does
 * not need those!
 */
export interface Checkup {
  createdBy: string;
  employeeId: string;
  serviceId: string;
  state: CovidState;
  vaccinatedDay?: Date;
  boosterDay?: Date;
  curedEndDay?: Date;
  testDate?: Date;
}

/**
 * Helper to create a new and preconfigured Checkup instance
 *
 * @param employee The selected employee to create this checkup for
 * @param creator The id of the tester who creates the checkup
 * @returns A new Checkup instance
 */
export const createNewCheckup = (
  employee: Employee,
  creator: string
): Checkup => ({
  createdBy: creator,
  employeeId: employee.mitarbeiterId,
  serviceId: employee.id,
  state: CovidState.NONE,
});
