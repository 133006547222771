import { AxiosInstance } from "axios";
import { User } from "./User.types";

/**
 * API method to load a single user instance
 *
 * @param userId The id of the instance to load
 * @param axios The axios instance
 * @returns Promise of an user object
 */
export const loadSingleUser = async (
  userId: string,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .get("/user/", { params: { userId: userId } })
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during user load!", exc));
};

/**
 * API method to load all user instances that are tester
 *
 * @param axios The axios instance
 * @returns Promise of a list of user
 */
export const loadAllTestUser = async (
  axios: AxiosInstance
): Promise<User[]> => {
  return axios
    .get("/user/all/tester/")
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during test user load!", exc));
};
