import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { UserContext } from "../pages/App";
import { UserRole } from "./user/User.types";

export const PrivateRoute: React.FC<{
  Page: React.ComponentType<any>;
  path: string;
  canEnter: UserRole[];
}> = ({ Page, path, canEnter }) => {
  const user = useContext(UserContext);
  return (
    <Route path={path} exact>
      {!!user && canEnter.includes(user.role) ? <Page /> : <Redirect to="/" />}
    </Route>
  );
};
