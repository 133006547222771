import { useTranslation } from "react-i18next";
import { Checkup, CovidState } from "../utils/checkup/Checkup.types";
import ButtonComponent from "./ButtonComponent";

const PopupComponent: React.FC<{
  checkup: Checkup;
  name?: string;
  visible: boolean;
  closeFunction(save: boolean): void;
}> = ({ checkup, name, visible, closeFunction }) => {
  const { t } = useTranslation();

  const getCorrectDay = (): string => {
    const dayConfig: any = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    const dateConfig: any = {
      hour: "2-digit",
      minute: "2-digit",
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };
    switch (checkup!.state) {
      case CovidState.CURED:
        return (
          checkup.curedEndDay?.toLocaleDateString("de-DE", dayConfig) || ""
        );
      case CovidState.VACCINATED:
        return (
          checkup.vaccinatedDay?.toLocaleDateString("de-DE", dayConfig) || ""
        );
      case CovidState.BOOSTERED:
        return checkup.boosterDay?.toLocaleDateString("de-DE", dayConfig) || "";
      case CovidState.TESTED:
      case CovidState.TESTED_EXTERN:
      case CovidState.TESTED_PCR:
      case CovidState.TESTED_PCR_EXTERN:
        return checkup.testDate?.toLocaleString("de-DE", dateConfig) || "";
      default:
        return "";
    }
  };

  return visible ? (
    <div className="popup-wrapper">
      <h2>{t("confirm.title")}</h2>
      <small>{t("confirm.employee")}</small>
      <b>{name}</b>
      <small>{t("confirm.state")}</small>
      <b>{t(`checkup.covidState.${checkup.state}`)}</b>
      <small>{t(`confirm.date.${checkup.state}`)}</small>
      <b>{getCorrectDay()}</b>
      <ButtonComponent
        onClick={() => closeFunction(true)}
        title={t("general.enter")}
      />
      <ButtonComponent
        onClick={() => closeFunction(false)}
        title={t("general.cancel")}
        background="#df081d"
      />
    </div>
  ) : (
    <></>
  );
};

export default PopupComponent;
