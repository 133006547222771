import { AxiosInstance } from "axios";

export const uploadTesterCsv = async (
  file: File,
  axios: AxiosInstance
): Promise<boolean> => {
  // build the request
  let uploadFormData = new FormData();
  uploadFormData.append("csv", file);
  return axios
    .post("/import/tester/", uploadFormData)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during tester upload!", exc);
      return false;
    });
};

export const uploadCheckupCsv = async (
  file: File,
  axios: AxiosInstance
): Promise<boolean> => {
  // build the request
  let uploadFormData = new FormData();
  uploadFormData.append("csv", file);
  return axios
    .post("/import/checkup/", uploadFormData)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during checkup upload!", exc);
      return false;
    });
};
