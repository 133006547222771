import { AxiosInstance } from "axios";
import { Employee } from "./Employee.types";

/**
 * API method to load all employees that match the given query string
 *
 * @param name The entered name to search for
 * @param axios The axios instance
 * @returns Promise of a list of employee instances
 */
export const findEmployeeByName = async (
  name: string,
  axios: AxiosInstance
): Promise<Employee[]> => {
  return axios
    .get("/employee/name/", { params: { name: name } })
    .then((resp) => resp.data)
    .catch((exc) => console.error("Error during employee name finding!", exc));
};
