interface InputComponentProps {
  value: string;
  onChange(newValue: string): void;
  disabled?: boolean;
  type?: "date" | "datetime-local" | "mail" | "text";
  placeholder?: string;
}

const InputComponent: React.FC<InputComponentProps> = ({
  onChange,
  type = "text",
  value,
  disabled,
  placeholder,
}) => {
  return (
    <div className="input-component">
      <input
        value={value}
        disabled={disabled}
        onChange={(evt) => onChange(evt.target.value)}
        type={type}
        required
        placeholder={placeholder}
      />
    </div>
  );
};

export default InputComponent;
