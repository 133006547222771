import { AxiosInstance } from "axios";
import { Checkup } from "./Checkup.types";

/**
 * API method to create a checkup on the backend
 *
 * @param checkup The created checkup instance
 * @param axios The axios instance
 * @returns A promise of true if successful, false otherwise
 */
export const saveCheckup = async (
  checkup: Checkup,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/checkup/", checkup)
    .then(() => true)
    .catch((exc) => {
      console.error("Error during checkup creation", exc);
      return false;
    });
};
