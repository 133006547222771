// Generated with util/create-component.js
import React, { useEffect, useState } from "react";

import { DropdownComponentProps, Option } from "./DropdownComponent.types";
import { ReactComponent as ClearIcon } from "../../assets/icons/close.svg";

import "./DropdownComponent.scss";

const DropdownComponent: React.FC<DropdownComponentProps> = ({
  disabled,
  required,
  options,
  selectedValue,
  onChange,
  searchable,
  allowVariableValue,
  label,
  placeholder,
  classNameOptions,
  classNameOption,
  classNameInput,
  classNameClearIcon,
  classNameLabel,
}) => {
  const [position, setPosition] = useState<DOMRect>();
  const [showOptions, toggleShowOptions] = useState<boolean>(false);
  const [variableValue, setVariableValue] = useState<string>("");
  const [mouseOnOptions, toggleMouseOnOptions] = useState<boolean>(false);
  const [optionsForDropdown, setOptionsForDropdown] = useState<Option[]>([]);
  //unique id for Dropdown
  const randomId: string = (
    Math.random() * Number(Date.now().toFixed(0))
  ).toFixed(0);

  //sets event handler for scrolling of parent element
  useEffect(() => {
    const parent: HTMLElement =
      document.getElementById(randomId)!.parentElement!;
    if (parent) parent.onscroll = () => abortSelection();
    // eslint-disable-next-line
  }, []);
  //event handler for scrolling of window
  window.onscroll = () => abortSelection();

  //add start to label and placeholder when required
  const labelText: string = label ? `${label}${required ? "*" : ""}` : "";
  const placeholderText: string = placeholder
    ? `${placeholder}${required ? "*" : ""}`
    : "";

  /**
   * closes dropdown
   */
  const abortSelection = (): void => {
    toggleMouseOnOptions(false);
    toggleShowOptions(false);
    if (!allowVariableValue) setVariableValue("");
  };

  //inittially sets selected Value as variable value, when variable text is allowed
  useEffect(() => {
    if (allowVariableValue && selectedValue) setVariableValue(selectedValue);
    // eslint-disable-next-line
  }, []);

  //we use variableValue for search too, if searchable is selected, else we just set all options
  useEffect(() => {
    if (variableValue && searchable) {
      const filteredOptions: Option[] = options.filter((option) =>
        option.value.toUpperCase().includes(variableValue.toUpperCase())
      );
      setOptionsForDropdown([...filteredOptions]);
    } else setOptionsForDropdown(options);
    // eslint-disable-next-line
  }, [variableValue, options]);

  return (
    <div
      data-testid="DropdownComponent"
      className="dropdown-component"
      id={randomId}
    >
      {labelText && (
        <label
          className={[classNameLabel ? classNameLabel : undefined].join(" ")}
        >
          {labelText}
        </label>
      )}
      <div className={"dropdown-component--value-wrapper"}>
        <input
          className={[
            "dropdown-component--value",
            classNameInput ? classNameInput : undefined,
            disabled || searchable || allowVariableValue
              ? undefined
              : "no-lower-opacity",
          ].join(" ")}
          placeholder={placeholderText}
          disabled={disabled || (!searchable && !allowVariableValue)}
          value={
            options.find((option) => option.value === selectedValue)?.label ||
            (searchable || allowVariableValue ? variableValue : "")
          }
          onChange={(event) => {
            setVariableValue(event.target.value);
            if (allowVariableValue) onChange(event.target.value);
          }}
          onClick={() => {
            if (disabled) return;
            const boundingClientRect: DOMRect = document
              .getElementById(randomId)!
              .getBoundingClientRect();
            setPosition(boundingClientRect);
            toggleShowOptions(!showOptions);
          }}
          onBlur={() => {
            toggleShowOptions(false);
            if (!allowVariableValue && !mouseOnOptions) setVariableValue("");
          }}
        />
        {/* once display input is disabled, required & onClicl events do not work, so we create an invisible trigger element that is not disabled */}
        <input
          className={[
            "dropdown-component--required-trigger",
            !searchable && !allowVariableValue
              ? undefined
              : "no-pointer-events",
          ].join(" ")}
          required={required}
          value={
            options.find((option) => option.value === selectedValue)?.label ||
            (searchable || allowVariableValue ? variableValue : "")
          }
          onChange={() => {}}
          onClick={() => {
            if (disabled) return;
            const boundingClientRect: DOMRect = document
              .getElementById(randomId)!
              .getBoundingClientRect();
            setPosition(boundingClientRect);
            toggleShowOptions(!showOptions);
          }}
          onBlur={() => {
            toggleShowOptions(false);
            if (!allowVariableValue) setVariableValue("");
          }}
        />
        {selectedValue && !disabled && (
          <ClearIcon
            className={[
              "dropdown-component--clear",
              classNameClearIcon ? classNameClearIcon : undefined,
            ].join(" ")}
            onClick={(event) => {
              event.stopPropagation();
              onChange("");
              setVariableValue("");
            }}
          />
        )}
      </div>
      <div
        key={`dropdown-component-options-${optionsForDropdown.length}`}
        className={[
          "dropdown-component--options",
          classNameOptions ? classNameOptions : undefined,
        ].join(" ")}
        hidden={!showOptions && !mouseOnOptions}
        style={
          position && {
            top: position?.top + position?.height,
            left: position?.left,
            width: position?.width,
            position: "fixed",
          }
        }
        onMouseEnter={() => toggleMouseOnOptions(true)}
        onMouseLeave={() => toggleMouseOnOptions(false)}
      >
        {optionsForDropdown.map((option, index) => (
          <div
            key={`dropdown-component-option-${index}-${option.value}-${optionsForDropdown.length}`}
            className={[
              "dropdown-component--options--option",
              classNameOption ? classNameOption : undefined,
              option.disabled ? "disabled" : undefined,
            ].join(" ")}
            onClick={() => {
              if (option.disabled) return;
              onChange(option.value);
              abortSelection();
            }}
          >
            {option.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default DropdownComponent;
