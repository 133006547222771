import { Employee } from "../utils/employee/Employee.types";

interface SelectListComponentProps {
  entries: Employee[];
  onClick(employee: Employee): void;
  title?: string;
}

const SelectListComponent: React.FC<SelectListComponentProps> = ({
  entries,
  onClick,
  title,
}) => {
  return (
    <div className={`select-list-component`}>
      {title && <p className="select-list-component--title">{title}</p>}
      {entries.map((entry, index) => (
        <div
          key={`select-entry-${index}`}
          className="select-list-component--entry"
          onClick={() => onClick(entry)}
        >
          {entry.name} <br />
          {entry.gesellschaft}
        </div>
      ))}
    </div>
  );
};

export default SelectListComponent;
