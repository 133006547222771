import { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import ButtonComponent from "../components/ButtonComponent";
import InputComponent from "../components/InputComponent";
import LayoutComponent from "../components/LayoutComponent";
import SelectListComponent from "../components/SelectListComponent";
import { useAxios } from "../utils/AxiosUtil";
import { Employee } from "../utils/employee/Employee.types";
import { findEmployeeByName } from "../utils/employee/Employee.util";
import { UserRole } from "../utils/user/User.types";
import { UserContext } from "./App";

const Dashboard: React.FC<{}> = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  const { t } = useTranslation();
  const history = useHistory();
  const user = useContext(UserContext);
  const axios = useAxios();
  const [showWarning, toggleWarning] = useState<boolean>(false);
  const formRef = useRef(null);
  const [showTesterUploadSuccess, toggleTesterUploadSuccess] =
    useState<boolean>(false);
  const [showCheckupUploadSuccess, toggleCheckupUploadSuccess] =
    useState<boolean>(false);
  const [showTesterUploadFail, toggleTesterUploadFail] =
    useState<boolean>(false);
  const [showCheckupUploadFail, toggleCheckupUploadFail] =
    useState<boolean>(false);
  const [employeeSelect, setEmployeeSelect] = useState<Employee[]>([]);

  const lookForEmployee = (): void => {
    if (!searchValue || /^\s*$/.test(searchValue) || /,/.test(searchValue)) {
      setSearchValue("");
      toggleWarning(true);
      return;
    }
    toggleWarning(false);
    setEmployeeSelect([]);
    findEmployeeByName(searchValue, axios).then((results) => {
      if (results.length === 0) {
        toggleWarning(true);
        return;
      } else if (results.length === 1) {
        history.push("/checkup", {
          employee: results[0],
        });
        return;
      } else {
        setEmployeeSelect(results);
      }
    });
  };

  return (
    <LayoutComponent>
      {user!.role === UserRole.ADMIN && (
        <>
          <h2>{t("dashboard.importTitle")}</h2>
          <ButtonComponent
            title={t("general.testerUpload")}
            fileUpload
            fileType="tester"
            uploadSuccess={toggleTesterUploadSuccess}
            uploadFail={toggleTesterUploadFail}
          />
          {showTesterUploadSuccess && (
            <p className="upload-success">{t("general.uploadSuccess")}</p>
          )}
          {showTesterUploadFail && (
            <p className="upload-warning">{t("general.uploadFail")}</p>
          )}
          <ButtonComponent
            title={t("general.checkupUpload")}
            fileUpload
            fileType="checkup"
            uploadSuccess={toggleCheckupUploadSuccess}
            uploadFail={toggleCheckupUploadFail}
          />
          {showCheckupUploadSuccess && (
            <p className="upload-success">{t("general.uploadSuccess")}</p>
          )}
          {showCheckupUploadFail && (
            <p className="upload-warning">{t("general.uploadFail")}</p>
          )}
        </>
      )}
      <h2>{t("dashboard.title")}</h2>
      <form
        ref={formRef}
        onSubmit={(evt) => {
          evt.preventDefault();
          lookForEmployee();
        }}
      >
        <InputComponent
          onChange={setSearchValue}
          value={searchValue}
          placeholder={t("general.search")}
        />
        {showWarning && (
          <p className="not-found-warning">{t("general.notFound")}</p>
        )}
        <ButtonComponent
          title={t("general.searchButton")}
          onClick={() => lookForEmployee()}
        />
      </form>
      {user!.role === UserRole.ADMIN && (
        <ButtonComponent
          title={t("general.loadAllTester")}
          onClick={() => history.push("/tester")}
        />
      )}
      {employeeSelect.length > 0 && (
        <SelectListComponent
          title={t("dashboard.selectTitle")}
          entries={employeeSelect}
          onClick={(empl) => history.push("/checkup", { employee: empl })}
        />
      )}
    </LayoutComponent>
  );
};

export default Dashboard;
