import { useKeycloak } from "@react-keycloak/web";
import { createContext, useEffect, useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import LoaderComponent from "../components/LoaderComponent";
import { useAxios } from "../utils/AxiosUtil";
import { PrivateRoute } from "../utils/PrivateRoute";
import { User, UserRole } from "../utils/user/User.types";
import { loadSingleUser } from "../utils/user/User.utils";
import CheckupPage from "./CheckupPage";
import Dashboard from "./Dashboard";
import TesterPage from "./TesterPage";

/**
 * This context holds the currently logged in backend user (not keycloak!)
 * which might be undefined
 */
export const UserContext = createContext<User | undefined>(undefined);

const App = () => {
  const { initialized, keycloak } = useKeycloak();
  const [loadedUser, setLoadedUser] = useState<User>();
  const axios = useAxios();

  /**
   * Helper method to load the backend user as soon as keycloak is authenticated
   */
  useEffect(() => {
    if (initialized && keycloak.authenticated && axios && !loadedUser)
      keycloak
        .loadUserProfile()
        .then((profile) =>
          loadSingleUser((profile as any).attributes.serviceId[0], axios).then(
            (serverUser) => setLoadedUser(serverUser)
          )
        );
    // eslint-disable-next-line
  }, [keycloak, axios]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          {initialized && keycloak && keycloak.authenticated ? (
            loadedUser ? (
              <Redirect to="/dashboard" />
            ) : (
              <LoaderComponent />
            )
          ) : (
            () => keycloak.login()
          )}
        </Route>
        <UserContext.Provider value={loadedUser}>
          <PrivateRoute
            Page={Dashboard}
            canEnter={[UserRole.ADMIN, UserRole.TESTER]}
            path={"/dashboard"}
          />
          <PrivateRoute
            Page={CheckupPage}
            canEnter={[UserRole.ADMIN, UserRole.TESTER]}
            path={"/checkup"}
          />
          <PrivateRoute
            Page={TesterPage}
            canEnter={[UserRole.ADMIN]}
            path={"/tester"}
          />
        </UserContext.Provider>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
