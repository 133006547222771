import Logo from "../assets/images/logo.png";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { useKeycloak } from "@react-keycloak/web";

const LayoutComponent: React.FC<{}> = ({ children }) => {
  const { keycloak } = useKeycloak();

  return (
    <div id="layout-wrapper">
      <div id="header-wrapper">
        <img id="logo" src={Logo} alt="Schäflein Logo" />
        <LogoutIcon id="logout-icon" onClick={() => keycloak.logout()} />
      </div>
      <div id="content-wrapper">{children}</div>
    </div>
  );
};

export default LayoutComponent;
